import { combineReducers } from '@reduxjs/toolkit';
import { product } from 'store/entities/product';
import { stockOrders } from 'store/entities/stockOrders';
import { store } from 'store/entities/store';
import { supplier } from 'store/entities/supplier';
import { supplierAccounts } from 'store/entities/supplierAccounts';

export const entities = combineReducers({
  product,
  store,
  supplier,
  supplierAccounts,
  stockOrders,
});
