import type { ThemeColorName, IconName } from '@partstech/ui';
import type { AvailabilityLine as AvailabilityLineType } from 'types/product';

export class AvailabilityLine {
  address: string | null;

  group: string | null;

  id: number | null;

  name: string;

  nickname: string | null = null;

  qty: AvailabilityLineType['qty'];

  type: AvailabilityLineType['type'];

  constructor(availabilityLine: AvailabilityLineType) {
    this.address = availabilityLine.address;
    this.group = availabilityLine.group;
    this.id = availabilityLine.id;
    this.name = availabilityLine.name;
    this.qty = availabilityLine.qty;
    this.type = availabilityLine.type;
  }

  get isMaxQuantity() {
    return this.qty.value > 99;
  }

  get formattedQuantity() {
    if (this.qty.type === 'GT') {
      return `${this.qty.value}+`;
    }

    if (this.qty.type === 'EQ') {
      return `${this.qty.value}`;
    }

    return '';
  }

  get formattedMaxQuantity() {
    if (this.isMaxQuantity) {
      return `99+`;
    }

    return this.formattedQuantity;
  }

  isNetwork() {
    return this.type === 'NETWORK';
  }

  isMain() {
    return this.type === 'MAIN';
  }

  isMainInStock() {
    return this.isMain() && this.qty.value > 0;
  }

  isNetworkInStock() {
    return this.isNetwork() && this.qty.value > 0;
  }

  get quantityColor(): ThemeColorName {
    if (this.qty.value === 0) {
      return 'negative';
    }

    if (this.isNetwork()) {
      return 'neutral';
    }

    return 'positive';
  }

  get displayName(): string {
    return this.nickname ?? this.name;
  }

  isEqual(item?: AvailabilityLine): boolean {
    return Boolean(item && ((item.id !== null && item.id === this.id) || item.name === this.name));
  }

  getIcon(): IconName | undefined {
    return 'storefront';
  }

  getTitle() {
    if (this.isMain()) {
      return this.getMainAvailabilityTitle();
    }

    if (this.isNetwork()) {
      return this.getNetworkAvailabilityTitle();
    }

    return null;
  }

  getMainAvailabilityTitle(): string {
    return `In Stock (${this.formattedMaxQuantity})`;
  }

  getNetworkAvailabilityTitle(): string {
    return `Available (${this.formattedMaxQuantity})`;
  }

  setNickname(nickname?: string | null) {
    this.nickname = nickname ?? null;
  }
}
