import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';
import type { LDFlagSet } from 'types/launchdarkly';

export const useGetLaunchDarklyFlags = () => {
  const {
    availabilityFilterEnhancements,
    clearSearchBarAfterChangingVehicle,
    failedOrdersVisibility,
    fluidsChemicalsTab,
    jobsVisibility,
    newOnboardingWidget,
    newReportBanner,
    newWelcomeModal,
    optionsPreferences,
    postOrderEtas,
    reCaptcha,
    reorderNonHomeStorePreferredBrands,
    saveLaborInCart,
    selectedFiltersChip,
    tireQuoteGql,
    vehicleSpecificJobs,
    xlPartsTphEtas,
    xlPartsTphEtasUi,
  } = useFlags();

  const [flags, setFlags] = useState<LDFlagSet>({});

  useEffect(() => {
    setFlags({
      availabilityFilterEnhancements,
      clearSearchBarAfterChangingVehicle,
      failedOrdersVisibility,
      fluidsChemicalsTab,
      jobsVisibility,
      newOnboardingWidget,
      newReportBanner,
      newWelcomeModal,
      optionsPreferences,
      postOrderEtas,
      reCaptcha,
      reorderNonHomeStorePreferredBrands,
      saveLaborInCart,
      selectedFiltersChip,
      tireQuoteGql,
      vehicleSpecificJobs,
      xlPartsTphEtas,
      xlPartsTphEtasUi,
    });
  }, [
    availabilityFilterEnhancements,
    clearSearchBarAfterChangingVehicle,
    failedOrdersVisibility,
    fluidsChemicalsTab,
    jobsVisibility,
    newOnboardingWidget,
    newReportBanner,
    newWelcomeModal,
    postOrderEtas,
    optionsPreferences,
    reCaptcha,
    reorderNonHomeStorePreferredBrands,
    saveLaborInCart,
    selectedFiltersChip,
    tireQuoteGql,
    vehicleSpecificJobs,
    xlPartsTphEtas,
    xlPartsTphEtasUi,
  ]);

  return flags;
};
