export enum LocalStorageKeys {
  SKIPPED_EXPIRE_CARD_DAY = 'skippedExpireCardDay',
  IS_SUBSCRIPTION_REMINDER_SKIPPED = 'isSubscriptionReminderSkipped',
  SEARCH_SEQUENCE = 'searchSequence',
  LAST_URL = 'lastUrl',
  LAST_SEARCH_URL = 'lastSearchUrl',
  NOTIFICATION_ID = 'notificationID',
  HIDE_SPLITTED_STOCK_ORDER_MESSAGE = 'hideSplittedStockOrderMessage',
  CART_LAST_UPDATED_TIME = 'cartLastUpdatedTime',
}

export enum SessionStorageKeys {
  HIDDEN_SIGNUP_STEPPER = 'hidden_signup_stepper',
  IS_MITCHELL1_SIGN_UP = 'isMitchell1SignUp',
  IS_SIGN_UP_WELCOME_BACK_SKIPPED = 'isSignUpWelcomeBackSkipped',
  PRODUCT_SORT = 'product_sort_value',
  QUOTE_ALERT = 'quote_alert_dismiss',
  QUOTE_CREATING = 'quote_creating',
  ONBOARD_CARD_VISIBILITY = 'onboard_card_visibility',
}
