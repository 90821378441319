import { AvailabilityLine, FeaturedAvailabilityLine, InNetworkAvailabilityLine } from 'models';
import type { AvailabilityLine as AvailabilityLineType, AvailabilityLineVariant } from 'types/product';

export const createAvailability = (line: AvailabilityLineType, variant?: AvailabilityLineVariant) => {
  switch (variant) {
    case 'featured':
    case 'partialMain':
      return new FeaturedAvailabilityLine(line);

    case 'inNetwork':
      return new InNetworkAvailabilityLine(line);

    default:
      return new AvailabilityLine(line);
  }
};
