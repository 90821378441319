import { selectSupplierAccountFromRootStoreById } from 'store/queries/currentUser/supplierAccounts/selectSupplierAccountFromRootStoreById';
import type { StockOrder, StockOrderEntity } from './stockOrdersSlice';
import type { RootState } from 'store';

export const denormalizeStockOrder = (rootState: RootState, entity: StockOrder): StockOrderEntity => ({
  ...entity,
  supplierAccount: entity.supplierAccountId
    ? selectSupplierAccountFromRootStoreById(rootState, String(entity.supplierAccountId))
    : null,
});
