import { Brand, PartNumber, Product } from 'models';
import { ProductInterchangeType } from 'shared/api';
import { createQuoteFromQuery } from './quote/createQuoteFromQuery';
import type { SupplierAccount } from 'models';
import type { ProductAttribute, ProductRebate } from 'shared/api';
import type { RawAttribute } from 'shared/api/rest/gen/shop';
import type { BaseProductFragment } from 'store/queries/searchResults/BaseProductFragment.generated';

const createImages = (product: BaseProductFragment) => ({
  images: product.images,
  image360Groups: product.image360Groups.map((group) => group.images),
  manufacturer: product.brand?.logo ?? null,
});

const createInterchangePart = (product: BaseProductFragment) => {
  const getType = (type: ProductInterchangeType | undefined): 'INTCH' | 'SUPERSEDE' | 'ALTERNATE' => {
    switch (type) {
      case ProductInterchangeType.Alternate:
        return 'ALTERNATE';

      case ProductInterchangeType.Interchange:
        return 'INTCH';

      case ProductInterchangeType.Supersede:
        return 'SUPERSEDE';

      default:
        return 'INTCH';
    }
  };

  if (!product.interchangeFor) {
    return null;
  }

  return {
    type: getType(product.interchangeFor?.type),
    partNumber: product.interchangeFor?.partNumber ?? '',
    brand: product.interchangeFor?.brandName ?? '',
  };
};

const createRebates = (rebates: ProductRebate[]) =>
  rebates.map((rebate) => ({
    couponLink: rebate.couponLink ?? null,
    description: rebate.description,
    legalLink: rebate.legalLink ?? null,
    price: rebate.price ?? 0,
    text: rebate.title,
    title: rebate.title,
    validDate: rebate.validDate ?? null,
  }));

const createAttributes = (attributes: ProductAttribute[]) =>
  attributes.map((attribute) => ({
    name: attribute.name as RawAttribute['name'],
    value: attribute.values,
  }));

type Params = {
  account?: SupplierAccount | null;
  product: BaseProductFragment;
  showRetailPrice?: boolean;
  vehicleId?: string | null;
  isPreferred?: boolean;
  filterEnhancements?: boolean;
};

export const createBaseProductFromQuery = ({
  account,
  product,
  showRetailPrice = false,
  vehicleId,
  isPreferred = false,
  filterEnhancements = false,
}: Params) => {
  const model = new Product({
    attributes: createAttributes(product.attributes),
    bundles: product.bundles,
    coreCharge: product.coreCharge ?? null,
    credentialId: account?.id ?? null,
    descriptions: product.descriptions,
    fetCharge: product.fetCharge ?? null,
    hasVehicleFitment: product.hasFitment,
    id: product.id,
    images: createImages(product),
    interchangePart: createInterchangePart(product),
    lineCardId: product.lineCard?.id ? Number(product.lineCard?.id) : null,
    listPrice: product.listPrice ?? null,
    mapPrice: product.mapPrice ?? null,
    notes: product.notes.map((note) => note.group),
    notesOem: product.notesOem,
    partTypeId: product.partType?.id ?? null,
    partTypeName: product.partType?.name ?? null,
    price: product.price ?? null,
    quantityStep: product.quantityStep ?? null,
    rebates: createRebates(product.rebates),
    retailPrice: product.customerPrice ?? null,
    rewardPoints: product.rewardPoints
      ? {
          isPromotion: product.rewardPoints.isPromotion,
          points: product.rewardPoints.points,
          bonus: 0,
          defaultPoints: 0,
          multipliers: 0,
          program: '',
        }
      : null,
    sponsoredPurchasesCount: product.sponsoredPurchasesCount ?? null,
    sponsorType: product.sponsorType ?? null,
    stockMessage:
      product.deliveryNotes.length > 0
        ? product.deliveryNotes.reduce<Record<string, string>>((acc, note) => ({ ...acc, [note.label]: note.text }), {})
        : null,
    shippingCharge: product.shippingCharge ?? false,
    sortHash: product.sortHash ?? '',
    title: product.title,
    variations: product.variations.map((variation) => ({
      ...variation,
      attributes: createAttributes(variation.attributes),
      notes: variation.notes.map((note) => note.group),
      partTitle: '',
      variationId: variation.variationID,
    })),
    vehicleId: vehicleId ? Number(vehicleId) : null,
  });

  if (account?.supplier) {
    model.setSupplier(account.supplier);
  }

  if (account?.store) {
    model.setStore(account.store);
  }

  model.setShowRetailPrice(showRetailPrice);

  model.setPartNumber(new PartNumber({ partNumber: product.partNumber, id: product.partNumberId }));

  if (product.brand) {
    model.setBrand(
      new Brand({
        id: product.brand.id,
        isPreferred,
        lineCode: product.brand.lineCode ?? null,
        logo: product.brand.logo ?? null,
        name: product.brand.name ?? null,
      })
    );
  }

  const quote = createQuoteFromQuery(product, model.stockMessage, account, filterEnhancements);

  if (quote) {
    model.setQuote(quote);
  }

  return model;
};
