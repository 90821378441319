import { isEmpty, isNotNull } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { createShopMembershipFromData } from 'factories/createShopMembershipFromData';
import { createSupplierMembershipFromData } from 'factories/createSupplierMembershipFromData';
import { getUserFullName, getUserInitials } from 'utils';
import { api as generatedApi } from './GetCurrentUser.generated';
import type { GetCurrentUserQuery } from './GetCurrentUser.generated';
import type { ShopMembership, SupplierMembership } from 'models';

export type CurrentUser = NonNullable<GetCurrentUserQuery['currentUser']>;
export type ActiveMember = NonNullable<CurrentUser['activeMember']>;
export type Shop = NonNullable<ActiveMember['shop']>;
export type FeatureFlags = NonNullable<Shop['featureFlags']>;
export type ManagementSystem = NonNullable<Shop['managementSystem']>;

const emptyShops: ShopMembership[] = [];
const emptySuppliers: SupplierMembership[] = [];

export const api = generatedApi.enhanceEndpoints({
  addTagTypes: ['CurrentUser', 'LaborRate'],
  endpoints: {
    GetCurrentUser: {
      providesTags: (response: GetCurrentUserQuery | undefined) => {
        const shop = response?.currentUser?.activeMember?.shop;
        if (!shop) {
          return ['CurrentUser'];
        }

        return ['CurrentUser'];
      },
    },
  },
});

const { useGetCurrentUserQuery, endpoints } = api;

type Args = {
  skip?: boolean;
};

const useGetCurrentUser = ({ skip = false }: Args = {}) => {
  const { data, ...props } = useGetCurrentUserQuery(undefined, { skip });

  const currentUser = data?.currentUser;
  const isConferenceAccount = currentUser?.isConferenceAccount ?? false;

  const accountId = currentUser?.id ?? null;
  const activeMember = currentUser?.activeMember;
  const allowOrderEmails = currentUser?.allowOrdersEmail ?? false;
  const apiKey = activeMember?.apiKey ?? null;
  const avatar = currentUser?.avatar ?? null;
  const email = currentUser?.email ?? null;
  const firstName = currentUser?.firstName ?? '';
  const fullName = currentUser ? getUserFullName(currentUser) : '';
  const initials = currentUser ? getUserInitials(currentUser) : '';
  const isGoogleAccount = currentUser?.isGoogleAccount ?? false;
  const isPrimary = Boolean(activeMember?.isPrimary);
  const lastName = currentUser?.lastName ?? '';
  const phone = currentUser?.phone ?? null;
  const phoneType = currentUser?.phoneType ?? null;
  const registrationDate = currentUser?.registrationDate ?? null;
  const shop = activeMember?.shop;

  const shopMemberships = useMemo(
    () => currentUser?.shopMemberships?.map(createShopMembershipFromData).filter(isNotNull) ?? emptyShops,
    [currentUser?.shopMemberships]
  );

  const shopName = shop?.name ?? '';
  const supplierMemberships = useMemo(
    () => currentUser?.supplierMemberships?.map(createSupplierMembershipFromData).filter(isNotNull) ?? emptySuppliers,
    [currentUser?.supplierMemberships]
  );
  const username = currentUser?.username ?? null;

  const isAllowedToModifySuppliers = shop?.allowToModifySuppliers ?? false;
  const isDemo = shop?.attributes?.demo;
  const exProfileType = shop?.exProfileType ?? null;

  const featureFlags = shop?.featureFlags;
  const hasCurrentUser = Boolean(currentUser);
  const isMechanic = !isEmpty(activeMember);
  const isSupplier = !isMechanic && Boolean(supplierMemberships.length);
  const showCatalog = shop?.catalog ?? false;
  const showRetailPrice = shop?.showRetailPrice ?? false;

  const managementSystem = useMemo(() => {
    const shopManagementSystem = shop?.managementSystem;

    if (!shopManagementSystem) {
      return null;
    }

    if ('id' in shopManagementSystem) {
      return shopManagementSystem;
    }

    return { id: null, name: shopManagementSystem?.name, logo: null, manual: null, status: null };
  }, [shop?.managementSystem]);

  return {
    accountId,
    activeMember,
    allowOrderEmails,
    apiKey,
    avatar,
    email,
    exProfileType,
    featureFlags,
    firstName,
    fullName,
    initials,
    isAllowedToModifySuppliers,
    isDemo,
    isGoogleAccount,
    isPrimary,
    lastName,
    managementSystem,
    phone,
    phoneType,
    registrationDate,
    shop,
    shopMemberships,
    shopName,
    showCatalog,
    showRetailPrice,
    supplierMemberships,
    username,
    hasCurrentUser,
    isMechanic,
    isSupplier,
    isConferenceAccount,
    ...props,
  };
};

export { useGetCurrentUser, endpoints };
