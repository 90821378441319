import { sortBy } from '@partstech/ui/utils';
import { createSelector } from '@reduxjs/toolkit';
import { createBaseProductFromQuery } from 'factories';
import { selectRootState } from 'store/utils';
import { createProductResult, generateSearchInput } from 'utils';
import { api as generatedApi, useLazyGetProductsQuery } from './GetProducts.generated';
import { getSearchInputWithoutAttributes } from './getSearchInputWithoutAttributes';
import type { BaseProductFragment } from './BaseProductFragment.generated';
import type { QueryStatus } from '@reduxjs/toolkit/query';
import type { SupplierAccount, TireSize, Product } from 'models';
import type { SearchInput } from 'shared/api';
import type { StockOrderRow } from 'types/stockOrders';

const api = generatedApi.enhanceEndpoints({
  addTagTypes: ['Product'],
  endpoints: {
    GetProducts: {
      providesTags: () => [{ type: 'Product' }],
      serializeQueryArgs: ({ queryArgs }) => {
        if (!queryArgs.searchInput) {
          return '';
        }

        return `Product(${JSON.stringify(getSearchInputWithoutAttributes(queryArgs.searchInput))})`;
      },
    },
  },
});

export const useGetLazyProducts = () => {
  const [trigger] = useLazyGetProductsQuery();

  return {
    getProducts: trigger,
  };
};

export const selectSearchResults = createSelector(
  [selectRootState, (_, params: { accountId: string; searchInput: SearchInput }[]) => params],
  (rootState, params) =>
    params.reduce<Record<string, { status: QueryStatus; products: BaseProductFragment[]; errors?: string[] }>>(
      (acc, { accountId, searchInput }) => {
        const result = api.endpoints.GetProducts.select({ searchInput })(rootState);

        return {
          ...acc,
          [accountId]: createProductResult({
            status: result.status,
            products: result.data?.products?.products ?? [],
            errors: result.error?.message ? [result.error?.message ?? ''] : result.data?.products?.errors,
          }),
        };
      },
      {}
    )
);

type StockOrders = { id: string; supplierAccount: SupplierAccount | null; rows: StockOrderRow[] };
type Params = { stockOrders: StockOrders[]; tiresSizeList: TireSize[]; filterEnhancements?: boolean };

export const selectStockSearchResults = createSelector(
  [selectRootState, (_: unknown, params: Params) => params],
  (rootState, { stockOrders, tiresSizeList, filterEnhancements }) =>
    stockOrders.reduce<
      Record<
        string,
        Record<string, { isLoading: boolean; isUninitialized: boolean; products: Product[]; errors?: string[] }>
      >
    >(
      (acc, { id, rows, supplierAccount }) => ({
        ...acc,
        [id]: rows.reduce<
          Record<string, { isLoading: boolean; isUninitialized: boolean; products: Product[]; errors?: string[] }>
        >((lineAcc, line, index) => {
          const searchInput = generateSearchInput(
            line.searchQuery,
            Boolean(supplierAccount?.supplier?.isTire),
            String(supplierAccount?.id),
            tiresSizeList
          );
          const result = api.endpoints.GetProducts.select({
            searchInput,
          })(rootState);

          return {
            ...lineAcc,
            [index]: {
              isLoading: result.status === 'pending',
              isUninitialized: result.status === 'uninitialized',
              products:
                sortBy(
                  result.data?.products?.products?.map((product) =>
                    createBaseProductFromQuery({
                      product,
                      account: supplierAccount,
                      filterEnhancements,
                    })
                  ) ?? [],
                  'sortHash'
                ) ?? [],
              errors: result.error?.message ? [result.error?.message ?? ''] : result.data?.products?.errors,
            },
          };
        }, {}),
      }),
      {}
    )
);
