import { usePrevious } from '@partstech/ui/hooks';
import { isEqual } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { useLaunchDarkly } from 'integrations/launchDarkly';
import { UniversalPartType } from 'models';
import { useAppSelector } from 'store';
import { selectLastSearch } from 'store/features/search/settings';
import { useDynamicAttributes } from './useDynamicAttributes';
import { useSearchParams } from './useSearchParams';
import type { SearchEntryPointInterface } from 'features/searchForm';
import type { SearchParams } from 'types/search';

const isPrefillAttributes = (
  lastSearch: SearchParams | null,
  valuableParams: SearchParams,
  prevValuableParams?: SearchParams,
  searchEntry?: SearchEntryPointInterface | null,
  isFittedAvailable?: boolean,
  hasDependentAttribute?: boolean
) => {
  if (!lastSearch && prevValuableParams) {
    return false;
  }

  if (prevValuableParams?.fit && !isEqual(prevValuableParams.fit, valuableParams.fit)) {
    return false;
  }

  if (isEqual(lastSearch?.attributes, valuableParams.attributes)) {
    return false;
  }

  if (Boolean(prevValuableParams?.attributes) && !isEqual(prevValuableParams?.attributes, valuableParams.attributes)) {
    return false;
  }

  if (isFittedAvailable && searchEntry instanceof UniversalPartType) {
    if (!searchEntry.isMultiAttribute()) {
      if (!hasDependentAttribute && !prevValuableParams?.attributes && Boolean(valuableParams.attributes)) {
        return false;
      }

      return Boolean(valuableParams?.vehicle) && Boolean(valuableParams?.attributes);
    }

    return searchEntry.hasSomeFilledAttributes();
  }

  return Boolean(valuableParams?.vehicle) && Boolean(valuableParams?.attributes);
};

export const useIsSameSearch = () => {
  const { valuableParams, searchEntry } = useSearchParams();
  const {
    flags: { fluidsChemicalsTab },
  } = useLaunchDarkly();

  const { hasDependentAttribute } = useDynamicAttributes();

  const lastSearch = useAppSelector(selectLastSearch);

  const previousValuableParams = usePrevious(valuableParams);

  return useMemo(
    () =>
      isPrefillAttributes(
        lastSearch,
        valuableParams,
        previousValuableParams,
        searchEntry,
        fluidsChemicalsTab,
        hasDependentAttribute
      ) || isEqual(lastSearch, valuableParams),
    [lastSearch, valuableParams, previousValuableParams, searchEntry, fluidsChemicalsTab, hasDependentAttribute]
  );
};
